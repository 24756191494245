<template>
  <div>
    <div v-for="(visit, key) in visits" :key="key">
      <div class="flex-col mb-4 border-b">
        <div class="flex justify-between">
          <div>{{ localDate(visit.visit_date) }}</div>
          <div v-if="visit && visit.created_by">{{ visit.created_by.first_name }} {{ visit.created_by.last_name }}</div>
        </div>
        <div>
          <h3>Registrerade moduler</h3>
          <div v-for="(m, key) in moduleRegistrations(visit)" :key="key">{{ m }}</div>
        </div>
        <cm-button @click="loadVisit(visit.id)">{{ visit.id }}</cm-button>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat } from "@/utils/date";

export default {
  name: "VisitDisplay",
  props: ["visits"],
  methods: {
    async loadVisit(id) {
      await this.$store.dispatch("visit/load", id);
      this.$modal.show("patient-visit", id);
    },
    localDate(date) {
      return dateFormat(date);
    },
    moduleRegistrations(visit) {
      return visit.module_registrations.map(d => d.module.name);
    },
  },
};
</script>

<style>
</style>