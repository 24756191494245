<template>
  <div class="p-2">
    <div class="my-2">
      <cm-button visual="primary" size="medium" @click="$modal.show('patient-visit')"
        >{{ $t("patient.visits.new") }}
      </cm-button>
    </div>

    <div class="border-t py-2">
      <visit-display :visits="visits" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VisitDisplay from "@/components/patient/visit/VisitDisplay";

export default {
  name: "PatientVisits",
  components: {
    VisitDisplay,
  },
  created() {
    this.loadVisits();
  },
  computed: {
    ...mapGetters("visit", ["visits"]),
  },
  methods: {
    async loadVisits() {
      this.$store.dispatch("visit/loadAll");
    },
  },
};
</script>
